
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        







































































































































































































































































































































@import '@/styles/_colors.scss';

.wrapper {
  width: 100%;

  @media screen and (min-width: bp(l)) {
    display: flex;
  }
}

.miller {
  display: grid;
  grid-gap: 1.75rem 0;
  grid-template-columns: repeat(1, 1fr);
  align-self: flex-start;
  background: white;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba($color-grey-400, 0.3);

  @media screen and (min-width: bp(s)) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: bp(m)) {
    width: span(9, 0, span(9));
  }

  @media screen and (min-width: bp(l)) {
    width: span(7, 0, span(10));
  }

  @media screen and (min-width: bp(xl)) {
    width: span(6, 0, span(10));
  }
}

.miller__col {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: 2px solid $color-grey-100;

  &:first-child {
    border-left: none;
  }

  @media screen and (min-width: bp(s)) {
    min-height: 15rem;
  }

  @media screen and (min-width: bp(m)) {
    min-height: 25rem;
  }
}

.miller__col-heading {
  padding: 0.5rem 0.75rem;
  color: $color-grey-600;
  border-bottom: 1px solid $color-grey-100;
}

.miller__link {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: var(--color-text);
  text-decoration: none;
  border-bottom: 1px solid $color-grey-100;

  &.selected {
    color: var(--color-text);
    font-weight: 500;
    background: $color-grey-50;
  }

  &.active {
    color: var(--color-text-secondary);
    font-weight: 500;
    background: var(--color-primary);
  }
}

.miller__list {
  max-height: 11rem;
  overflow: auto;

  @media screen and (min-width: bp(s)) {
    max-height: 20rem;
  }

  @media screen and (min-width: bp(m)) {
    max-height: 25rem;
  }

  @media screen and (min-width: bp(l)) {
    max-height: 35rem;
  }
}

.miller__add {
  margin-top: auto;
  border-top: 1px solid $color-grey-100;
}

.miller__icon {
  align-self: flex-start;
  margin-right: 0.35rem;
  padding-top: 0.2rem;
  opacity: 0.75;
}

.miller__archived {
  align-self: flex-start;
  margin-left: auto;
  padding-top: 0.2rem;
  padding-left: 0.5rem;
}

.details {
  margin-top: 1rem;
  padding: 1rem;
  background: white;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba($color-grey-400, 0.3);

  @media screen and (min-width: bp(l)) {
    align-self: flex-start;
    width: span(3, 0, span(10));
    margin-top: 0;
    margin-left: span(0, 1, span(10));
  }

  @media screen and (min-width: bp(xl)) {
    width: span(3, 0, span(10));
    margin-left: span(1, 2, span(10));
  }
}
